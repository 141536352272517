import {
  DashboardOutlined,
  UserOutlined,
  FileOutlined,
  ExceptionOutlined,
  CloudUploadOutlined,
  DollarOutlined,
  NotificationOutlined,
  BankOutlined,
  FileImageOutlined,
  ConsoleSqlOutlined,
  HistoryOutlined,
  UnorderedListOutlined,
  MedicineBoxOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  SolutionOutlined,
  ScheduleOutlined,
  ScanOutlined,
  TagsOutlined,
  VideoCameraAddOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  CalculatorOutlined,
  UserAddOutlined,
  ToolOutlined,
  ToolTwoTone,
  SettingOutlined,
  ContainerOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { APP_PREFIX_PATH } from "configs/AppConfig";
import path from "path";
const enterpriseConnectedId = localStorage.getItem("EnterpriseId") || null;
const dashBoardNavTree = [
 
];

const usersRelatedNavTree = [
  {
    key: "doctors",
    path: `${APP_PREFIX_PATH}/doctors`,
    title: " ",
    roles: ["ADMIN", "COORDINATEUR","ENTERPRISE","CASHIER","CALLCENTER"],
    submenu: [      
      {
        key: "home",
        path: `${APP_PREFIX_PATH}/home`,
        title: "home",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
        roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
      },
     
      {
        key: "consultation",
        path: `${APP_PREFIX_PATH}/consultation/list/wait`,
        title: "Télémédecine ",
        icon: SolutionOutlined,
        roles: ["ADMIN", "COORDINATEUR","ENTERPRISE","CASHIER"],
        breadcrumb: false,
        submenu: [
          {
            key: "consultation.wait",
            path: `${APP_PREFIX_PATH}/consultation/list/wait`,
            title: "Télé-Expertise",
            icon: ExceptionOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
          },
          {
            key: "consultation.wait",
            path: `${APP_PREFIX_PATH}/compterendu`,
            title: "Télé-Expertise",
            icon: ExceptionOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["CASHIER"],
          },
          {
            key: "teleconsultation.list",
            path: `${APP_PREFIX_PATH}/probs/list`,
            title: "Astreinte Téléconsultation",
            icon: ConsoleSqlOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
          },
          {
            key: "imagerie-medical",
            path: `${APP_PREFIX_PATH}/imagerie-medical`,
            title: "Imagerie médicale ",
            icon: ScanOutlined,
            breadcrumb: true,
            roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
            submenu :[
        
        ],
      },
      {
        key: "eprescription",
        path: `${APP_PREFIX_PATH}/eprescription`,
        title: "eprescription",
        icon: ContainerOutlined,
        breadcrumb: false,
        roles: ["ADMIN", "COORDINATEUR","CASHIER"],
        submenu: [
          {
            key: "eprescription.ordenance",
            path: `${APP_PREFIX_PATH}/eprescription/new-medics/list`,
            title: "eprescription.newMedics",
            icon: MedicineBoxOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
          {
            key: "eprescription.type",
            path: `${APP_PREFIX_PATH}/eprescription/typical/list`,
            title: "eprescription.typical",
            icon: ExceptionOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
          {
            key: "eprescription.history",
            path: `${APP_PREFIX_PATH}/eprescription/history/list`,
            title: "eprescription.history",
            icon: HistoryOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
          },
          {
            key: "typical_responses",
            path: `${APP_PREFIX_PATH}/typicals-responses`,
            title: "Comptes Rendus Types",
            icon: FileOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
        ],
      },
        ]
      },
      

      {
        key: "yata_patient",
          path: `${APP_PREFIX_PATH}/patients`,
          title: "Centre d'appel",
          icon: PhoneOutlined,
          breadcrumb: false,
          roles: ["ADMIN", "COORDINATEUR","CALLCENTER"],
          submenu:[
            {
              key: "appointments.online",
              path: `${APP_PREFIX_PATH}/appointment/online`,
              title: "Télé-Surveillance",
              icon: VideoCameraAddOutlined,
              breadcrumb: false,
              submenu: [],
              roles: ["ADMIN", "COORDINATEUR","ENTERPRISE","CALLCENTER"],
            },
          
            {
              key: "appointments.office",
              path: `${APP_PREFIX_PATH}/appointment/office`,
              title: "Rendez-vous au cabinet",
              icon: ScheduleOutlined ,
              breadcrumb: false,
              submenu: [],
              roles: ["ADMIN", "COORDINATEUR","ENTERPRISE","CALLCENTER"],
            },
            // {
            //   key: "homecare",
            //   path: `${APP_PREFIX_PATH}/home-care-requests`,
            //   title: "Soins a domicile",
            //   icon: HomeOutlined,
            //   breadcrumb: false,
            //   submenu: [],
            //   roles: ["ADMIN", "COORDINATEUR"],
            // },
            {
              key: "appointments",
              path: `${APP_PREFIX_PATH}/in-home-sampling-requests`,
              title: "Prelevement a domicile",
              icon: MedicineBoxOutlined,
              breadcrumb: false,
              submenu: [],
              roles: ["ADMIN", "COORDINATEUR"],
            },
            {
              key: "biosensor.listPatient",
              path: `${APP_PREFIX_PATH}/biosensors/patches`,
              title: "Biocapteurs ",
              icon: MedicineBoxOutlined,
              breadcrumb: false,
              submenu: [],
              roles: ["ADMIN", "COORDINATEUR"],
            },
          ]
      },
      {
        key: "count",
        title: "Comptabilte",
        breadcrumb: false,
        icon:CalculatorOutlined,
        roles: ["ADMIN", "COORDINATEUR","ENTERPRISE" ,"CASHIER"],
        submenu: [
          {
            key: "pay",
            path: `${APP_PREFIX_PATH}/appointment/payments/listAppointments`,
            title: "Caisse ",
            icon: DollarOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR","ENTERPRISE" ,"CASHIER"],
          },
          {
            key: "claims.list",
            path: `${APP_PREFIX_PATH}/claims-request`,
            title: "Reclammation",
            icon: DatabaseOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
          },
      
          // {
          //   key: "count.settlements",
          //   path: `${APP_PREFIX_PATH}/settlements`,
          //   title: "count.settlements",
          //   icon: DollarOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          //   roles: ["ADMIN", "COORDINATEUR"],
          // },
          // {
          //   key: "count.transaction",
          //   path: `${APP_PREFIX_PATH}/transaction`,
          //   title: "count.transaction",
          //   icon: DollarOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          //   roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
          // },
        
         
          // {
          //   key: "#methods",
          //   path: `${APP_PREFIX_PATH}/payments/payment-methods`,
          //   title: "Méthodes de paiement",
          //   icon: DollarOutlined,
          //   breadcrumb: false,
          //   roles: ["ADMIN", "COORDINATEUR"],
          //   submenu: [],
          // },
        ],
      },
     
      
      {
        key: "appointments.office",
        path: `${APP_PREFIX_PATH}/appointment/office`,
        title: "Liste des rendez-vous",
        icon: ScheduleOutlined ,
        breadcrumb: false,
        submenu: [],
        roles: ["ENTERPRISE"],
      },
      {
        key: "user",
        path: `${APP_PREFIX_PATH}/team`,
        title: "sidenav.users",
        icon: UsergroupAddOutlined,
        breadcrumb: true,
        roles: ["ADMIN", "COORDINATEUR"],
        submenu: [
          {
            key: "patients",
            path: `${APP_PREFIX_PATH}/patients`,
            title: "Patients",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
              // {
              //   key: "medecins_notification",
              //   path: `${APP_PREFIX_PATH}/notification`,
              //   title: "Notifications des médecins",
              //   icon: NotificationOutlined,
              //   breadcrumb: false,
              //   submenu: [],
              //   roles: ["ADMIN", "COORDINATEUR", "ENTERPRISE"],
              // },
              {
                key: "medecins",
                path: `${APP_PREFIX_PATH}/doctors`,
                title: "Médecins",
                icon: UserOutlined,
                breadcrumb: true,
                submenu: [],
                roles: ["ADMIN", "COORDINATEUR"],
              },
              // {
              //   key: "request",
              //   path: `${APP_PREFIX_PATH}/requests/list`,
              //   title: "Creation des Comptes",
              //   icon: UserAddOutlined,
              //   breadcrumb: true,
              //   submenu: [],
              //   roles: ["ADMIN", "COORDINATEUR"],
              // },
              {
                key: "health_professional",
                path: `${APP_PREFIX_PATH}/healthprofessional`,
                title: "Professionnel de santé",
                icon: MedicineBoxOutlined,
                breadcrumb: false,
                submenu: [],
                roles: ["ADMIN", "COORDINATEUR", "ENTERPRISE"],
              },
            ],
      }
      ,
      
  
    // {
    //   key: "speciality",
    //   path: `${APP_PREFIX_PATH}/actifSpecialties`,
    //   title: "Menu Télésurveillance patient",
    //   icon: UnorderedListOutlined,
    //   breadcrumb: false,
    //   roles: ["ADMIN", "COORDINATEUR"],
    //   submenu: []
    // },
 
    
    // {
    //   key: "biocapteur",
    //   path: `${APP_PREFIX_PATH}/enterprise`,
    //   title: "Biocapteurs",
    //   icon: UnorderedListOutlined,
    //   breadcrumb: true,
    //   roles: ["ADMIN", "COORDINATEUR"],
    //   submenu:[
    //     {
    //       key: "biosensor.listPatient",
    //       path: `${APP_PREFIX_PATH}/biosensors/patches`,
    //       title: "	Liste des commandes ",
    //       icon: MedicineBoxOutlined,
    //       breadcrumb: false,
    //       submenu: [],
    //       roles: ["ADMIN", "COORDINATEUR"],
    //     },
    //     {
    //       key: "biosensor.listContract",
    //       path: `${APP_PREFIX_PATH}/biosensors/contracts`,
    //       title: "Liste des clients ",
    //       icon: ContactsOutlined,
    //       breadcrumb: false,
    //       submenu: [],
    //       roles: ["ADMIN", "COORDINATEUR"],
    //     },
        
    //   ]
    // },
    
      {
        key: "gestion_enterprise",
        path: `${APP_PREFIX_PATH}/enterprise`,
        title: "Établissement de santé ",
        icon: BankOutlined,
        breadcrumb: false,
        roles: ["ADMIN", "COORDINATEUR"],
        submenu: [
          // {
          //   key: "approve_enterprise",
          //   path: `${APP_PREFIX_PATH}/enterprise/approve_enterprise`,
          //   title: "Approuver la demande",
          //   icon: BankOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          //   roles: ["ADMIN", "COORDINATEUR"],
          // },
          {
            key: "enterprise",
            path: `${APP_PREFIX_PATH}/enterprise`,
            title: "Hôpitaux ",
            icon: BankOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
         
          {
            key: "laboratory",
            path: `${APP_PREFIX_PATH}/laboratory`,
            title: "Laboratoire",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
        ],
      },
    
      
      {
        key: "orderManagement",
        path: `${APP_PREFIX_PATH}/order-management`,
        title: "E-commerce",
        icon: DashboardOutlined,
        breadcrumb: false,
        roles: ["ADMIN", "COORDINATEUR"],
        submenu: [
          {
            key: "order",
            path: `${APP_PREFIX_PATH}/order-requests`,
            title: "Historiques ",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
          {
            key: "categories",
            path: `${APP_PREFIX_PATH}/categories`,
            title: "Gestion des categories",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },

          {
            key: "produits",
            path: `${APP_PREFIX_PATH}/products`,
            title: "Gestion des produits",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
            roles: ["ADMIN", "COORDINATEUR"],
          },
        ],
       
      },
      
        {
          key:  "professionnel_de_sante" ,
          path: `${APP_PREFIX_PATH}/team`,
          title:  "Professionnel de santé",
          icon: UsergroupAddOutlined,
          breadcrumb: true,
          roles: "ENTERPRISE",
          submenu: []
      ,
      },
     
    //   {
    //     key:  "header" ,
    //     path: `${APP_PREFIX_PATH}/header`,
    //     title:  "Entete",
    //     icon: UsergroupAddOutlined,
    //     breadcrumb: true,
    //     roles: "ENTERPRISE",
    //     submenu: []
    // ,
    // },
    {
      key: "parametres",
      title: "Parametrage",
      breadcrumb: false,
      icon:SettingOutlined ,
      roles: ["ADMIN", "COORDINATEUR","ENTERPRISE" ],
      submenu: [
        {
          key: "speciality",
          path: `${APP_PREFIX_PATH}/actifSpecialties`,
          title: "Menu Télésurveillance patient",
          icon: UnorderedListOutlined,
          breadcrumb: false,
          roles: ["ADMIN", "COORDINATEUR"],
          submenu: []
        }
        ,
        {
          key: "actes",
          path: `${APP_PREFIX_PATH}/accounting/service`,
          title: "Creaction des actes",
          icon: UnorderedListOutlined,
          breadcrumb: false,
          roles: ["ADMIN", "COORDINATEUR","ENTERPRISE"],
          submenu: []
        },
        
      {
        key:  "header" ,
        path: `${APP_PREFIX_PATH}/header`,
        title:  "Entete",
        icon: UsergroupAddOutlined,
        breadcrumb: true,
        roles: "ENTERPRISE",
        submenu: []
    ,
    },
      ]
    }
     
    ],
  },
];

const navigationConfig = [
  // ...dashBoardNavTree,
  ...usersRelatedNavTree,
];

export default navigationConfig;

import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Card, Input, Switch, Button, Space, message, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Service from 'services/EprescriptionMedicamentService';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { useHistory } from 'react-router-dom';

const Details = (props) => {
    const [state, setState] = useState(null);
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (props.location.state) {
            setState(props.location.state);
            form.setFieldsValue(props.location.state);
        } else {
            history.push(`${APP_PREFIX_PATH}/eprescription/new-medics`);
        }
    }, [props.location.state, form, history]);

    const onFinish = () => {
        form.validateFields().then(async (values) => {
            setSubmitLoading(true);
            try {
                if (state) {
                    await Service.update(values, state.id);
                    message.success('Modification enregistrée avec succès');
                } else {
                    await Service.create(values);
                    message.success('Enregistrement effectué avec succès');
                }
                history.push(`${APP_PREFIX_PATH}/eprescription/new-medics`);
            } catch (error) {
                message.error('Une erreur est survenue');
            } finally {
                setSubmitLoading(false);
            }
        });
    };

    const handleDelete = async () => {
        setModalLoading(true);
        try {
            await Service.delete(state.id);
            message.success('Supprimé avec succès');
            history.push(`${APP_PREFIX_PATH}/eprescription/new-medics`);
        } catch (error) {
            message.error('Erreur lors de la suppression');
        } finally {
            setModalLoading(false);
            setVisible(false);
        }
    };

    return (
        <Card
            title={state ? 'Modifier un Médicament' : 'Créer un Médicament'}
            extra={
                <Space>
                    {state && (
                        <Button
                            onClick={() => setVisible(true)}
                            shape='circle'
                            danger
                            icon={<DeleteOutlined />}
                        />
                    )}
                    <Button onClick={onFinish} loading={submitLoading} type='primary'>
                        Sauvegarder
                    </Button>
                </Space>
            }
        >
            <Modal
                visible={visible}
                confirmLoading={modalLoading}
                onCancel={() => setVisible(false)}
                onOk={handleDelete}
            >
                <h3>Voulez-vous vraiment supprimer ce médicament ?</h3>
            </Modal>

            <Form layout='vertical' form={form}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name='nomcommercial'
                            label='Nom Commercial'
                            rules={[{ required: true, message: 'Champ obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name='prix'
                            label='Prix'
                            rules={[{ required: true, message: 'Champ obligatoire' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name='approved' label='Approved?' valuePropName='checked'>
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='dci' label='DCI'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='formeDosage' label='Forme de Dosage'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='specialite' label='Spécialité'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='classePharmaceutique' label='Classe Pharmaceutique'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='indication' label='Indication'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='contreIndication' label='Contre-indication'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='reference' label='Référence'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='grossesseAllaitement' label='Grossesse / Allaitement'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='MAJministereSante' label='MAJ Ministère Santé'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='remboursementCNAM' label='Remboursement CNAM'>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default Details;

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'
import firebaseConfig from 'configs/FirebaseConfig';
import { getFirestore } from 'firebase/firestore/lite'

firebase.initializeApp(firebaseConfig);

const db =  firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

const currentUser = auth.currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();

const signInWithCustomToken = async (token) => {
	await auth.signInWithCustomToken(token);

	console.log(auth.currentUser);

	localStorage.setItem('Va', auth.currentUser?.uid ?? '');
};
export {
	db,
	auth,
	storage,
	currentUser,
	googleAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	githubAuthProvider,
	signInWithCustomToken
};
import { LOGIN_PATH } from "../configs/AppConfig";
import axios from "axios";
import decode from "jwt-decode";
import { AUTH_TOKEN } from "../redux/constants/Auth";
import { signInWithCustomToken } from "auth/FirebaseAuth";
import fetch from "../auth/FetchInterceptor";
import { message } from "antd";
import history from "history.js";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return axios.post(LOGIN_PATH, data, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch(error => {
    message.error('Login failed: ' + error.message);
    throw error;
  });
};

JwtAuthService.logout = function () {
  localStorage.clear();
};

JwtAuthService.isAuthenticated = function () {
  return !!localStorage.getItem(AUTH_TOKEN);
};

JwtAuthService.saveToken = async function (jwt) {
  localStorage.setItem(AUTH_TOKEN, jwt);
  const decodedToken = decode(jwt);

  if (decodedToken.firebase_auth_token) {
    signInWithCustomToken(decodedToken.firebase_auth_token);
  }

  const roles = decodedToken.roles ?? [];
  localStorage.setItem("username", decodedToken.username);

  const rolesWithLocalStorage = ["ADMIN", "COORDINATEUR", "COMPTABLE", "SECRETAIRE", "CALLCENTER"];
  const rolesWithEnterpriseFetch = ["ENTERPRISE", "CASHIER"];

  // Store roles in localStorage
  localStorage.setItem("roles", JSON.stringify(roles));
  console.log("Roles", roles);
  // Always fetch user info and save it
  try {
    console.log("Fetching user info");
    const data = await fetch.get("/auth/get-my-info");
    console.log("data", data);

    if (data && data.id) {
      localStorage.setItem("name", data.name);
      localStorage.setItem("enterpriseName", data.enterpriseName);
      
      if (roles.includes("CASHIER")) {
        localStorage.setItem("EnterpriseId", data.enterpriseId);
      } else if (roles.includes("ENTERPRISE")) {
        localStorage.setItem("EnterpriseId", data.id);
      }
    } else {
      console.error("No ID found in data");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    message.error("Error fetching enterprise data.");
  }

  // Redirect if unauthorized
  const url = window.location.href;
  if (roles.some(role => rolesWithLocalStorage.includes(role)) && url.includes("console")) {
    JwtAuthService.logout();
    message.error("Vous n'avez pas les droits pour accéder à cette page");
    history.push(`${APP_PREFIX_PATH}`);
    return true;
  }

  return true;
};

export default JwtAuthService;
